import React from 'react';

import PropTypes from 'prop-types';

import ExploreMintEnjoy from './ExploreMintEnjoy';
import AboutUs from './AboutUs';
import CreateCollection from './CreateCollection';
import ExploreArtists from './ExploreArtists';

import { PageRenderer, Button, BackgroundCardsGrid } from '@SharedComponents/';

import useGridBreakpoint from '@Hooks/useGridBreakpoint';

import navigation from '@Utils/navigation';

import { mainAppMarketplacePath } from '@Utils/routes.js.erb';
import { TokenShape } from '@Utils/propsShape';

const Home = ({ tokens }) => {
  const goToMarketplace = () => navigation(mainAppMarketplacePath());

  const { belowDesktop } = useGridBreakpoint();

  const buttonLabel = belowDesktop ? 'Explore now' : 'Explore';

  return (
    <main className="home">
      <div className="hero d-flex flex-column p-relative">
        <BackgroundCardsGrid columnSize={4} />
        <div className="hero-container grid flex-column p-relative h-100 mx-4 mx-lg-5">
          <div className="hero-text g-col-12 g-col-sm-12 g-col-lg-6 g-col-xl-6 g-col-xxl-5 d-flex flex-column gap-5">
            <h1 className="archivo-900 f-36 f-xxl-64">
              At Spreadmint you can buy NFTs and be even closer to the artists you love.
            </h1>
            <p className="nunito-400 f-18 color-gray-200">
              A platform that combines Crypto, Digital Art, NFTs and Social Media all together in
              one.
            </p>
            <Button
              className="align-self-center align-self-lg-start"
              label={buttonLabel}
              onClick={goToMarketplace}
            />
          </div>
        </div>
        <ExploreMintEnjoy />
        <AboutUs tokens={tokens} />
        <CreateCollection />
        <ExploreArtists />
      </div>
    </main>
  );
};

Home.propTypes = {
  tokens: PropTypes.arrayOf(TokenShape).isRequired,
};

export default (props, railsContext) => () =>
  <PageRenderer component={Home} props={{ ...props, footer: true, railsContext }} mainApp />;
