import React from 'react';

import sendEmail from '@Images/send_email.svg';

import { SPREADMINT_EMAIL } from '@Lib/constants';

const CreateCollection = () => {
  return (
    <section id="create-collection" className="hero-container centered-grid create-collection">
      <div className="create-collection-image">
        <img src={sendEmail} alt="create collection" />
      </div>
      <div className="create-collection-text">
        <p className="first-title archivo-800 f-18 text-gradient">Create collection</p>
        <p className="archivo-700 f-36">Join us with just one easy step!</p>
        <br />
        <p className="nunito-400 f-18">
          Can you envision yourself selling one-of-a-kind artworks and providing exceptional content
          to your fans? Share your ideas with us, and we&apos;ll gladly create a customized
          collection just for you!
        </p>
        <p className="avenir-400 f-18 pt-4 color-primary-blue">
          <b>{SPREADMINT_EMAIL}</b>
        </p>
      </div>
    </section>
  );
};

export default CreateCollection;
