import React from 'react';

import navigation from '@Utils/navigation';
import useGridBreakpoint from '@Hooks/useGridBreakpoint';
import { Button } from '@SharedComponents/index';

import { mainAppMarketplacePath } from '@Utils/routes.js.erb';

const ExploreArtists = () => {
  const goToMarketplace = () => navigation(mainAppMarketplacePath());
  const { belowDesktop } = useGridBreakpoint();
  const buttonLabel = belowDesktop ? 'Explore now' : 'Explore';

  return (
    <div className="hero-container centered-grid featured-artists mb-5">
      <p className="archivo-700 f-48 second-title mb-4">Follow your favorite artists, right now!</p>
      <Button
        className="align-self-center align-self-lg-start mb-5"
        label={buttonLabel}
        onClick={goToMarketplace}
      />
    </div>
  );
};

export default ExploreArtists;
