import React from 'react';
import PropTypes from 'prop-types';

import { addDays } from 'date-fns';

import tokensPlaceholders from './tokens_placeholders.json';

import { TokenShape } from '@Utils/propsShape';

import { TokenCard } from '@SharedComponents/index';

import shakiraImg from '@Images/shakira.svg';
import theWeekndImg from '@Images/the_weeknd.svg';

const AboutUs = ({ tokens }) => {
  tokensPlaceholders[0].imageUrl = shakiraImg;
  tokensPlaceholders[1].imageUrl = theWeekndImg;

  tokensPlaceholders[1].listing.expiresAt = addDays(new Date(), 7);

  let renderedTokens = '';
  if (tokens.length < 2) {
    renderedTokens = (
      <>
        <div className="zoomed">
          <TokenCard
            token={tokensPlaceholders[0]}
            favoriteAction={true}
            redirect={false}
            favoriteCallback={() => {}}
          />
        </div>
        <TokenCard
          token={tokensPlaceholders[1]}
          favoriteAction={true}
          redirect={false}
          favoriteCallback={() => {}}
        />
      </>
    );
  } else {
    renderedTokens = (
      <>
        <div className="zoomed">
          <TokenCard
            token={tokens[0]}
            favoriteAction={true}
            redirect={true}
            favoriteCallback={() => {}}
          />
        </div>
        <TokenCard
          token={tokens[1]}
          favoriteAction={true}
          redirect={true}
          favoriteCallback={() => {}}
        />
      </>
    );
  }

  return (
    <section id="about-us" className="hero-container centered-grid about-us pt-5">
      <div className="about-us-text">
        <p className="first-title archivo-800 f-18 text-gradient">About us</p>
        <p className="archivo-700 f-36">Revolutionizing the NFT market</p>
        <br />
        <p className="nunito-400 f-18">
          Spreadmint is a project that seeks to revolutionize the NFT market and the relations
          between artists and his or her fans. Trough our NFT’s you can be even closer to your
          favorite artists while investing money and discovering new ways of commercializing art.
        </p>
      </div>
      <div className="about-us tokens centered-grid">{renderedTokens}</div>
    </section>
  );
};

AboutUs.propTypes = {
  tokens: PropTypes.arrayOf(TokenShape).isRequired,
};

export default AboutUs;
