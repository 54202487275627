import React from 'react';

const ExploreMintEnjoy = () => {
  return (
    <div className="hero-container centered-grid explore-mint-enjoy">
      <div className="item">
        <p className="archivo-900 f-24">Explore</p>
        <p className="archivo-400 f-16">Collections</p>
      </div>
      <div className="item">
        <p className="archivo-900 f-24">Mint</p>
        <p className="archivo-400 f-16">Unique tokens</p>
      </div>
      <div className="item">
        <p className="archivo-900 f-24">Enjoy</p>
        <p className="archivo-400 f-16">Amazing rewards</p>
      </div>
    </div>
  );
};

export default ExploreMintEnjoy;
